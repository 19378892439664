import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_PRODUCTS_URL = process.env.VUE_APP_API_URL + "product";

class ProductService {
	getProducts(page) {
		return axios.get(API_PRODUCTS_URL + "/admin/paginated/" + page, {
			headers: authHeader(),
		});
	}

	getSingleProduct(id) {
		return axios.get(API_PRODUCTS_URL + "/admin/" + id, {
			headers: authHeader(),
		});
	}
	createProduct(product) {
		return axios.post(API_PRODUCTS_URL + "/", product, {
			headers: authHeader(),
		});
	}
	// Bulk import products
	importProduct(products) {
		return axios.post(
			API_PRODUCTS_URL + "/bulk/",
			{ products: products },
			{ headers: authHeader() }
		);
	}
	deleteProduct(id) {
		return axios.delete(API_PRODUCTS_URL + "/" + id, { headers: authHeader() });
	}
	updateProduct(id, product) {
		console.log("Update route", JSON.stringify(product, null, 4));
		console.log("In update");

		return axios.put(API_PRODUCTS_URL + "/" + id, product, {
			headers: authHeader(),
		});
	}
	// Updates images on the server
	updateProductImages(id, imgs, complete = true) {
		console.log("update images", imgs);
		return axios.patch(
			API_PRODUCTS_URL + "/images/" + id,
			{ img: imgs },
			{
				headers: { ...authHeader(), complete: complete },
			}
		);
	}

	// Remove a image from server
	removeImage(id, url) {
		return axios.patch(
			API_PRODUCTS_URL + "/image/" + id,
			{ img: url },
			{
				headers: authHeader(),
			}
		);
	}
}

export default new ProductService();
