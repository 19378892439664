<template>
  <div class="theme-dashboard-view">
    <h1>{{ $t("inventory_import_title") }}</h1>
    <p class="text-warning">* {{ $t("inventory_import_subtitle") }}</p>
    <a class="text-warning" id="downloadLink" target="_blank"
      href="https://storage.googleapis.com/static.deboldelivery.com/misc/product_bulk_sample_header.csv">
      {{ $t("download_sample_button_text") }}
    </a>
    <div>
      <div class="d-flex justify-content-center">
        <LoadingIndicator :active="loading" />
      </div>
      <div v-show="message">
        <div class="justify-content-center text-center my-4">
          <h5>{{ message }}</h5>
        </div>
      </div>
    </div>
    <div class="my-4" v-show="!loading">
      <!-- <input type="file" multiple @change="uploadFile" />
			<div
				@drop="dragFile"
				class="p-5 m-5"
				style="border: 1px grey dashed"
			>
				
				<div v-if="!File.length">Drag and drop</div>
				<div v-if="File.length">
					<ul v-for="file in File" :key="file">
						<li style="list-style-type: none">{{ file.name }}</li>
					</ul>
				</div>
			</div> -->
      <!-- TODO: Come and change this package, build own. Drag  and drop -->

      <vue-csv-import v-model="csv" :fields="{
        name: { required: true, label: 'Name' },
        price: { required: true, label: 'Price' },
        amoudi_quantity: { required: true, label: 'Amoudi Quantity' },
        nani_quantity: { required: true, label: 'Nani Quantity' },
        pos_id: { required: true, label: 'Pos Id' },
        pos_name: { required: true, label: 'Pos Name' },
        increment: { required: true, label: 'Increment' },
        maximum_order_quantity: { required: true, label: 'Max Order' },
        minimum_order_quantity: { required: true, label: 'Min Order' },
        category_id: { required: false, label: 'Category' },
      }">
        <vue-csv-errors></vue-csv-errors>

        <!-- Import Input -->
        <div class="d-flex flex-column align-items-center justify-content-center" style="border: 1px dotted black"
          :class="{ 'bg-success': csv.length != 0 }">
          <div class="d-flex align-items-center" style="min-height: 100px">
            <vue-csv-input> </vue-csv-input>
          </div>
        </div>

        <!-- Import Table -->
        <div class="shadow-sm border p-4 my-5" v-show="csv.length !== 0" style="min-height: 55vh">
          <div class="d-flex justify-content-between p-3">
            <h4>{{ $t("inventory_import_table_title") }}</h4>
            <div class="d-flex" style="height: 50px">
              <!-- <button class="theme-button bg-danger mx-2" style="min-width: 70px" @click="csv = []">Cancel</button> -->
              <button class="theme-button theme-background-light text-dark mx-2" @click="importFile"
                style="min-width: 70px">
                {{ $t("inventory_import_table_button") }}
              </button>
            </div>
          </div>
          <Table :rows="csv" />
        </div>



        <!-- Import settings -->
        <div class="py-4">
          <h5>{{ $t("inventory_import_settings_title") }}</h5>
          <div class="mx-4 my-3">
            <vue-csv-map></vue-csv-map>
            <br />
            <vue-csv-toggle-headers :checkboxAttributes="{ class: 'header-input' }">
              <!-- <button @click.prevent="toggle">
								<input type="checkbox" :value="hasHeaders" />
								Has Headers
							</button> -->
            </vue-csv-toggle-headers>
          </div>
        </div>
      </vue-csv-import>
    </div>
  </div>
</template>

<style scoped>
#downloadLink {
  text-decoration: none;
}
</style>

<script>
import {
  VueCsvToggleHeaders,
  VueCsvSubmit,
  VueCsvMap,
  VueCsvInput,
  VueCsvErrors,
  VueCsvImport,
} from "vue-csv-import";
import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

// Service
import ProductService from "@/services/products.js";
export default {
  name: "ImportProducts",
  components: {
    VueCsvToggleHeaders,
    VueCsvSubmit,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors,
    VueCsvImport,
    Table,
    LoadingIndicator,
  },
  data() {
    return {
      csv: [],
      loading: false,
      message: "",
      headerCheck: true,
    };
  },
  methods: {
    importFile() {
      this.loading = true;
      this.message = "";
      console.log(this.csv[0]);
      ProductService.importProduct(this.csv).then(
        (response) => {
          this.loading = false;
          this.message = response.data.message;
          this.csv = [];
        },
        (error) => {
          console.log(error.response);
          this.loading = false;
          this.message = error.response.data.message;
        }
      );
    },
  },
};
</script>
